import schttp from 'public/src/services/schttp'
import newBffUtils from './utils'

const { currency, appLanguage } =
  typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

let versionV1 = ['checkout_v1']
//  查询支付域图标信息
export const fetchPayDomainLogoApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    // node bff loginc, default payment_code is routepay-card
    if (!data.payment_code) {
      data.payment_code = 'routepay-card'
    }
    return await schttp({
      method: 'GET',
      url: `/order/get_payment_page_logo`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: data,
      useBffApi: true
    })
  } else {
    return await schttp({
      url: '/api/checkout/payDomainLogo/get',
      params: data
    })
  }
}
// 获取ccc弹窗内容
export const fetchChemicalsIdsApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    const { code, info } = await schttp({
      method: 'GET',
      url: `/ccc/dialog/content`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: {
        matchTagId: data.matchTagIds
      },
      useBffApi: true
    })
    if (code == '0' && info?.content) {
      return info.content
    }
    return ''
  } else {
    let p65Text = ''
    const res = await schttp({
      url: `/api/checkout/chemicalsIds/get`,
      params: {
        matchTagIds: JSON.stringify([data.matchTagIds])
      }
    })
    res?.data?.forEach(item => {
      let { tagId = '', content = [] } = item
      if (data.matchTagIds == tagId) {
        p65Text = content?.[0]?.content?.props?.items?.[0]?.content || ''
      }
    })
    return p65Text
  }
}

// 校验店配地址
export const fetchCheckStoreAddressApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    const res = await schttp({
      method: 'POST',
      url: `/user/address/store/check`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: {
        storeCountryId: data.countryId,
      },
      useBffApi: true
    })
    if (res.code !== '0' && res?.msg) {
      res.tips = res.msg
    }
    return res
  } else {
    return await schttp({
      method: 'POST',
      url: `/api/checkout/checkStoreAddress/get`,
      data: data
    })
  }
}

// 查询钱包交易记录数
export const fetchWalletInfoApi = async () => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    return await schttp({
      method: 'POST',
      url: '/user/wallet/freezing',
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      useBffApi: true
    })
  } else {
    return await schttp({
      url: '/api/checkout/walletInfo/get'
    })
  }
}
// ccc券列表公告
export const fetchCouponListNoticeApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    const res = await schttp({
      method: 'GET',
      url: `/order/checkout/coupon/notice`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: data,
      useBffApi: true
    })
    if (res?.code === '0' && res?.info?.content) {
      res.isOk = true
      res.data = res.info
    }
    return res
  } else {
    const res = await schttp({
      url: '/api/checkout/communalNoticeCouponlist/get',
      params: data
    })
    if (res?.code == '1000' && res.data) {
      res.isOk = true
    }
    return res
  }
}
